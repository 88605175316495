import React from 'react';
import Rocket from '../Rocket';

function Projects() {
    return (
        <div className="gradient-banner">
                <div className="content-box">
                    <h1>Projects</h1>
                    <p>Take a look at my projects developed in classes, for internships, or for fun!</p>
                </div>
                <Rocket />   
        </div>
        
    );
}

export default Projects;