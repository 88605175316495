import React from 'react';
import './Resume.css';
import profIcon from '../assets/prof.png';
import eduIcon from '../assets/edu.png';
import skillsIcon from '../assets/skill.png';
import yale from '../assets/yale-cross-campus.png';
import pdfResume from '../assets/resume.pdf';
import docResume from '../assets/resume.docx';
import WorkExperience from './WorkExperience';
import Rocket from '../Rocket';

function Resume() {
    return (
        <div>
            <div className="gradient-banner">
                <div className="content-box">
                    <h1>Resume</h1>
                    <p>Download the most recent version of my resume at the button below in .pdf or .docx format or scroll through an interactive version.</p>
                    <div className="download-buttons">
                        <a href={pdfResume} download="Erin_Foley_Resume.pdf" className="btn">Download PDF</a>
                        <a href={docResume} download="Erin_Foley_Resume.docx" className="btn">Download DOCX</a>
                    </div>
                </div>
            </div>

            <div className="resume-section education-section">
                <div className="experience-header">
                    <img src={eduIcon} alt="Education" className="section-icon" />
                    <h2>Education</h2>
                </div>
                <div className="section-content">
                    <div>
                        <img src={yale} alt="Yale Cross Campus" className="education-image" />
                    </div>
                    <h3>Yale University, New Haven, CT</h3>
                    <p className="education-dates">Expected Graduation 2026</p>
                    <p className="education-degree">BS in Computer Science - 3.94 GPA</p>
                    <p className="education-coursework">
                        <strong>Relevant Coursework:</strong> Introduction to Computer Science (Racket); Data Structures and Programming Techniques (C/C++); Introduction to Blockchains, Cryptocurrencies, Smart Contracts, and Decentralized Applications (Solidity); Discrete Mathematics; Introduction to Machine Learning (Python, PyTorch); Algorithms; Cognitive Science of Large Language Models (Python); Systems Programming and Computer Organization (IP); Computational Intelligence for Games with Reinforcement Learning (IP)
                    </p>
                    <p className="education-coursework">
                        <strong>Relevant Clubs/Organizations:</strong> President of Young Entrepreneurs Program, Yale Chapter
                    </p>
                </div>
            </div>

            <div className="resume-section professional-experiences-section">
                <div className="experience-header">
                    <img src={profIcon} alt="Professional Experience" className="section-icon" />
                    <h2>Professional Experiences</h2>
                </div>
                <div className="experience-section">
                    <div className="experience-images">
                        <img src="/nasa1.jpeg" alt="NASA 1" className="experience-image" />
                        <img src="/nasa2.png" alt="NASA 2" className="experience-image" />
                        <img src="/nasa3.png" alt="NASA 3" className="experience-image-tall" />
                        <img src="/sjf.jpeg" alt="Social Justice Fund" className="experience-image" />
                    </div>
                    <div className="experience-details">
                    <WorkExperience
                            company="National Aeronautics and Space Administration"
                            location="New York, NY"
                            dates="June - August 2021, 2022, 2024"
                            roles={[
                                {
                                    title: "Machine Learning Research Intern",
                                    period: "June 2024 - August 2024",
                                    responsibilities: [
                                        "Tuned a Random Forest Regressor in Python by an r-squared coefficient of 0.28 to and visualized harmful algal bloom predictions in New York State lakes with an r-squared of 0.68",
                                        "Accelerated climate scientist workflows by 100% by developing a full-stack web application with Svelte and Pocketbase.io to visualize predictions of harmful algal blooms",
                                        "Contributed around 1,000 lines of code, wrote abstracts, methodologies, and discussions for papers submitted to American Geophysical Union and American Meteorological Society"
                                    ]
                                }
                            ]}
                        />

                        <WorkExperience
                            company="National Aeronautics and Space Administration"
                            location="New York, NY"
                            dates="July 2022 - August 2022"
                            roles={[
                                {
                                    title: "Computer Science Research Intern",
                                    period: "July 2022 - August 2022",
                                    responsibilities: [
                                        "Improved research team’s understanding of the impact of blue-green infrastructure by quantifying cooling impact using indices such as NDWI and TOA to LST with numpy, rasterio, and GDAL",
                                        "Improved storage of raster data by around 40% by creating 3D matrix in Python to store temperature, point location, and time data"
                                    ]
                                }
                            ]}
                        />

                        <WorkExperience
                            company="National Aeronautics and Space Administration"
                            location="New York, NY"
                            dates="June 2021 - August 2021"
                            roles={[
                                {
                                    title: "Computer Science Research Intern",
                                    period: "June 2021 - August 2021",
                                    responsibilities: [
                                        "Developed and investigated interdisciplinary research question concerning the relationship between historic redlining policies and current climate change experience",
                                        "Created spatial maps of land surface temperature and vegetative health using JavaScript, ArcGIS, MATLAB, SQL and revealed patterns across redlined and non-redlined neighborhoods",
                                        "Co-authored two papers for American Geological Union 2022 and Frontiers in Hydrology 2022",
                                        "Contributed to NASA roundtable presentations, which resulted in the Robert H. Goddard Award from Goddard Institute for Space Studies being awarded to team"
                                    ]
                                }
                            ]}
                        />

                        <WorkExperience
                            company="Clara and Joe Tsai Foundation Social Justice Fund"
                            location="New York, NY"
                            dates="June 2024 - Present"
                            roles={[
                                {
                                    title: "Program Manager of AI Summer Bootcamp",
                                    period: "June 2024 - Present",
                                    responsibilities: [
                                        "Created and taught 5-week curriculum in LLMs, with 10 Python/Hugging Face projects",
                                        "Recruited and managed 16 teaching assistants including assessment and payments",
                                        "Organized panel of three industry leaders in AI from Goldman Sachs, Unity Designs, and NaML AI"
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="resume-section">
                <div className="experience-header">
                    <img src={skillsIcon} alt="Skills & Other" className="section-icon" />
                    <h2>Skills & Other</h2>
                </div>
                <div className="section-content">
                    <div className="skills-list">
                        <p><strong>Programming Languages:</strong> Python, Java, C/C++, JavaScript, Swift, HTML/CSS, Solidity, Racket</p>
                        <p><strong>Tools & Technologies:</strong> Git, Xcode, Google Earth Engine, ArcGIS, React, Node.js, SQL, Express, RabbitMQ/Messaging Queues, Object Oriented Programming, Machine Learning, Natural Language Processing</p>
                        <p><strong>Soft Skills:</strong> Leadership, Team Collaboration, Communication, Problem Solving, Critical Thinking</p>
                        <p><strong>Other Interests:</strong> Data Visualization, Cyber Security, AI Decision Making, User Research, UX Design</p>
                    </div>
                </div>
            </div>
            <Rocket />
        </div>
    );
}

export default Resume;
