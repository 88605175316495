import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Rocket.css';

function Rocket() {
    const [isFlying, setIsFlying] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight - 50) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigateToNextPage = useCallback(() => {
        const currentPath = window.location.pathname;
        switch (currentPath) {
            case '/':
                navigate('/resume');
                break;
            case '/resume':
                navigate('/research');
                break;
            case '/research':
                navigate('/projects');
                break;
            case '/projects':
                navigate('/blog');
                break;
            default:
                break;
        }
    }, [navigate]);

    useEffect(() => {
        if (isFlying) {
            const flyingRocketElement = document.querySelector('.flying-rocket');
            flyingRocketElement.classList.add('visible');

            setTimeout(() => {
                navigateToNextPage();
                setIsFlying(false);
            }, 5000); // 5 seconds to match the flight duration
        }
    }, [isFlying, navigateToNextPage]);

    const handleClick = () => {
        setIsFlying(true);
    };

    return (
        <div className={`rocket-container ${isVisible ? 'visible' : ''}`}>
            <img
                src="/rocket.png"
                alt="Rocket"
                className={`rocket ${isFlying ? 'hidden' : ''}`}
                onClick={handleClick}
            />
            <img
                src="/flying-rocket.png"
                alt="Flying Rocket"
                className={`flying-rocket ${isFlying ? 'visible' : ''}`}
            />
        </div>
    );
}

export default Rocket;
